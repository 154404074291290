import { SortDirection } from "../../../../../components/Tables/types";
import { Offer, OfferStats } from "../../../../types";
import firebase from "firebase/app";
import { CollectionNames, FirestoreError } from "../..";
import { getAllTimeOfferEventValue } from "../../tracker/client";
import { calculateConversionRate } from "../../../../conversionRate";
import { AnalyticsEventKeys } from "../../tracker/shared/constants";
import { OfferInDatabase } from "./interface";

export async function getAllOfferStats({
  firestore,
  shopId,
  offerUids,
}: {
  firestore: firebase.firestore.Firestore;
  shopId: string;
  offerUids: (string | null)[];
}): Promise<{ [key: string]: OfferStats }> {
  return await getAllOfferAllTimeEventValues({ firestore, shopId, offerUids });
}

async function getAllOfferAllTimeEventValues({
  firestore,
  shopId,
  offerUids,
}: {
  firestore: firebase.firestore.Firestore;
  shopId: string;
  offerUids: (string | null)[];
}) {
  const results: { [key: string]: OfferStats } = {};
  const promises = [];
  for (const offerUid of offerUids) {
    if (offerUid !== null) {
      const promise = getOfferStats({ firestore, shopId, offerUid }).then(
        (offerStats) => {
          results[offerUid] = offerStats;
        }
      );

      promises.push(promise);
    }
  }
  await Promise.all(promises);
  return results;
}

async function getOfferStats({
  firestore,
  shopId,
  offerUid,
}: {
  firestore: firebase.firestore.Firestore;
  shopId: string;
  offerUid: string;
}) {
  const ordersCountPromise = getAllTimeOfferEventValue({
    firestore,
    identifiers: {
      shopId,
      offerId: offerUid,
      key: AnalyticsEventKeys.ordersCount,
    },
  }).then((val) => {
    return val === null ? { value: 0, count: 0 } : val;
  });
  const revenuePromise = getAllTimeOfferEventValue({
    firestore,
    identifiers: {
      shopId,
      offerId: offerUid,
      key: AnalyticsEventKeys.revenue,
    },
  }).then((val) => {
    return val === null ? { value: 0, count: 0 } : val;
  });
  const impressionsPromise = getAllTimeOfferEventValue({
    firestore,
    identifiers: {
      shopId,
      offerId: offerUid,
      key: AnalyticsEventKeys.impressions,
    },
  }).then((val) => {
    return val === null ? { value: 0, count: 0 } : val;
  });
  const { value: impressions } = await impressionsPromise;
  const { value: ordersCount } = await ordersCountPromise;
  const { value: revenue } = await revenuePromise;
  const conversionRate = calculateConversionRate({
    ordersCount: ordersCount,
    impressions: impressions,
  });

  return {
    ordersCount,
    revenue,
    conversionRate,
    impressions,
  };
}

export async function getTopOffersStats({}: {
  firestore: firebase.firestore.Firestore;
  nToGet: number;
}): Promise<OfferStats[]> {
  throw new Error("Not implemented");
}

export function listenToAllOffers({
  firestore,
  onUpdate,
  orderBy,
  shopId,
}: {
  firestore: firebase.firestore.Firestore;
  shopId: string;
  onUpdate: (newOffers: Offer[]) => void;
  orderBy?: OrderOffersInfo;
}): () => void {
  const query = getAllOffersQuery({ firestore, orderBy, shopId });
  return query.onSnapshot((snapshot) => {
    const offers = snapshot.docs.map((snapshot) => snapshotToOffer(snapshot));
    onUpdate(offers);
  });
}

export async function getAllOffers({
  firestore,
  orderBy,
  fromCache,
  shopId,
}: {
  firestore: firebase.firestore.Firestore;
  shopId: string;
  orderBy?: OrderOffersInfo;
  fromCache?: boolean;
}): Promise<Offer[]> {
  const query = getAllOffersQuery({ firestore, orderBy, shopId });
  if (fromCache) {
    const offers = await fetchOffersFromQuery({ query, fromCache: true });
    if (offers.length === 0) {
      return await fetchOffersFromQuery({ query, fromCache: false });
    }
    return offers;
  }
  return await fetchOffersFromQuery({ query, fromCache: false });
}

export async function getOfferByUid({
  firestore,
  offerUid,
}: {
  offerUid: string;
  firestore: firebase.firestore.Firestore;
}): Promise<Offer> {
  return firestore
    .collection(CollectionNames.offers)
    .doc(offerUid)
    .get()
    .then((snapshot) => snapshotToOffer(snapshot));
}

export async function getOfferLastModified({
  firestore,
  shopId,
}: {
  firestore: firebase.firestore.Firestore;
  shopId: string;
}): Promise<Offer | null> {
  return firestore
    .collection(CollectionNames.offers)
    .where("shop", "==", shopId)
    .orderBy("dateLastModified", "desc")
    .limit(1)
    .get()
    .then((querySnapshot) => {
      const snapshot = querySnapshot.docs[0];
      if (!snapshot) {
        return null;
      }
      return snapshotToOffer(snapshot);
    });
}

async function fetchOffersFromQuery({
  query,
  fromCache,
}: {
  query: firebase.firestore.Query<firebase.firestore.DocumentData>;
  fromCache?: boolean;
}): Promise<Offer[]> {
  return await query
    .get({ source: fromCache ? "cache" : "default" })
    .then((querySnapshot) => {
      return querySnapshot.docs.map((snapshot) => snapshotToOffer(snapshot));
    });
}

interface OrderOffersInfo {
  orderByField: keyof Offer;
  direction: SortDirection;
}

function getAllOffersQuery({
  firestore,
  orderBy,
  shopId,
}: {
  firestore: firebase.firestore.Firestore;
  shopId: string;
  orderBy?: OrderOffersInfo;
}) {
  if (orderBy && orderBy.direction !== SortDirection.NONE) {
    const orderByString =
      orderBy.direction === SortDirection.ASCENDING ? "asc" : "desc";
    return firestore
      .collection(CollectionNames.offers)
      .where("shop", "==", shopId)
      .orderBy(orderBy.orderByField as string, orderByString)
      .orderBy("chosenId", "desc")
      .limit(100);
  }
  return firestore.collection(CollectionNames.offers).limit(100);
}

export function snapshotToOffer(
  snapshot:
    | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    | FirebaseFirestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
    | FirebaseFirestore.DocumentSnapshot<FirebaseFirestore.DocumentData>
): Offer {
  if (!snapshot.exists || !snapshot.data()) {
    throw new FirestoreError("Offer snapshot cannot be converted");
  }
  const offer = snapshot.data() as OfferInDatabase;
  const dateLastModified = offer.dateLastModified
    ? offer.dateLastModified.toDate()
    : undefined;
  return {
    ...offer,
    uid: snapshot.id,
    dateLastModified,
  };
}
