import firebase from "firebase/app";
import {
  EVENTS_COLLECTION,
  OFFERS_COLLECTION,
  SUMMARY_DOC,
  TOTAL_DOC,
} from "../shared/constants";
import {
  CompositeEventIdentifiers,
  OfferEventIdentifiers,
} from "../shared/types";

export async function getYearTotalEventValue({
  firestore,
  identifiers,
  year,
}: {
  identifiers: OfferEventIdentifiers;
  year: number;
  firestore: firebase.firestore.Firestore;
}): Promise<{ [key: string]: number } | null> {
  return getRootOfferEventCollection({ shopId: identifiers.shopId, firestore })
    .doc(identifiers.offerId)
    .collection(identifiers.key)
    .doc(year.toString())
    .get()
    .then((doc) => docToDataOrNull(doc));
}

export async function getCompositeEventValue({
  firestore,
  year,
  identifiers,
}: {
  identifiers: CompositeEventIdentifiers;
  year: number;
  firestore: firebase.firestore.Firestore;
}): Promise<{ [key: string]: number } | null> {
  return getRootOfferEventCollection({ shopId: identifiers.shopId, firestore })
    .doc(SUMMARY_DOC)
    .collection(identifiers.key)
    .doc(year.toString())
    .get()
    .then((doc) => docToDataOrNull(doc));
}

export async function getAllTimeOfferEventValue({
  firestore,
  identifiers,
}: {
  identifiers: OfferEventIdentifiers;
  firestore: firebase.firestore.Firestore;
}) {
  return getRootOfferEventCollection({ shopId: identifiers.shopId, firestore })
    .doc(identifiers.offerId)
    .collection(identifiers.key)
    .doc(TOTAL_DOC)
    .get()
    .then((doc) => getTotalValueFromDoc(doc));
}

export async function getAllTimeCompositeEventValue({
  firestore,
  identifiers,
}: {
  identifiers: CompositeEventIdentifiers;
  firestore: firebase.firestore.Firestore;
}) {
  return getRootOfferEventCollection({ shopId: identifiers.shopId, firestore })
    .doc(SUMMARY_DOC)
    .collection(identifiers.key)
    .doc(TOTAL_DOC)
    .get()
    .then((doc) => getTotalValueFromDoc(doc));
}

function getTotalValueFromDoc(doc: firebase.firestore.DocumentSnapshot) {
  const data = doc.data();
  if (data === undefined) {
    return null;
  }
  const { value, count } = data;
  if (typeof value !== "number" || typeof count !== "number") {
    return null;
  }
  return { value, count };
}

export function getRootOfferEventCollection({
  shopId,
  firestore,
}: {
  shopId: string;
  firestore: firebase.firestore.Firestore;
}) {
  return firestore
    .collection(EVENTS_COLLECTION)
    .doc(shopId)
    .collection(OFFERS_COLLECTION);
}

function docToDataOrNull(
  doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
) {
  const data = doc.data();
  if (!doc.exists || !data) {
    return null;
  }
  return data;
}
