import React, { MouseEventHandler } from "react";

export interface StaticHeaderItemData {
  content: React.ReactNode;
}

export interface SortableHeaderItemData {
  title: string;
  dataKey: string;
  allowedSortDirections: AllowedSortDirections;
}

export enum AllowedSortDirections {
  ASCENDING_ONLY = "AllowedSortDirections.ASCENDING_ONLY",
  DECENDING_ONLY = "AllowedSortDirections.DECENDING_ONLY",
  BOTH = "AllowedSortDirections.BOTH",
  NEITHER = "AllowedSortDirections.NEITHER",
}

export interface SortDetails {
  sortDirection: SortDirection;
  keyOfSortedColumn: keyof TableRowData;
}

export type InitialSortDetails = SortDetails | SortDirection.NONE;

export interface StaticTableProps {
  header: React.ReactNode[];
  data: React.ReactNode[][];
  shouldSkipHeaderItemConstruction?: boolean;
}

export type OnSortCallbackFn = (
  sortDirection: SortDirection,
  dataKey: keyof TableRowData
) => void;

export interface SortableTableProps {
  header: SortableHeaderItemData[];
  data: TableBodyData;
  initialSortDetails: InitialSortDetails;
  onSortCallback: OnSortCallbackFn;
  stickyTopPosition?: string;
}

export enum IsLoading {
  true = 1,
}

export type TableCellContent = React.ReactNode | IsLoading;
export type TableBodyData = TableRowData[];
export interface TableRowData {
  [key: string]: TableCellContent;
}

export interface TableCellProps {
  content: TableCellContent;
  colIndex: number;
}

export interface StaticHeaderItemProps {
  content: React.ReactNode;
  isFirstCol: boolean;
  isLastCol: boolean;
  stickyTopPosition?: string;
}

export interface SortableHeaderItemProps {
  title: React.ReactNode;
  isFirstCol: boolean;
  isLastCol: boolean;
  sortDirection: SortDirection;
  onClick: MouseEventHandler<HTMLTableHeaderCellElement>;
  allowedSortDirection: AllowedSortDirections;
  stickyTopPosition?: string;
}

export enum SortDirection {
  NONE = "Sort.NONE",
  ASCENDING = "Sort.ASCENDING",
  DESCENDING = "Sort.DESCENDING",
}
