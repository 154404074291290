export const EVENTS_COLLECTION = "events";
export const OFFERS_COLLECTION = "offers";

export const SUMMARY_DOC = "SUMMARY_DOC";
export const TOTAL_DOC = "total";

export const AnalyticsEventKeys = {
  revenue: "revenue",
  impressions: "impressions",
  ordersCount: "ordersCount",
  orderValue: "orderValue",
};
