export function calculateConversionRate({
  ordersCount,
  impressions,
}: {
  ordersCount: number;
  impressions: number;
}): number {
  if (impressions === 0) {
    return 0;
  }
  return (ordersCount / impressions) * 100;
}
